@use "shared" as *;

.left-bar {
  width: 340px;
  min-width: 225px;
  max-width: 470px;
  // flex: 2.8;
  padding-left: 8px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-right: 8px;
  position: relative;
  user-select: none;
  // transition-property: width, opacity, transform;
  //   transition-duration: 50ms;
  //   transition-timing-function: ease;
}

.resizer {
  position: absolute;
  height: 100%;
  right: -5px;
  top: 0;
  padding: 0 5px;
  cursor: col-resize;

  z-index: 4;
  border-radius: 10px;
}

.resizer:hover {
  background: $milky-coconut;
}

.resizer_browser {
  padding: 5px 0;
  background-color: $white-color;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  // padding: 0 8px;
  cursor: row-resize;
  z-index: 4;
  border-radius: 10px;
}

.resizer_browser:hover{
  background: $milky-coconut;
}

.resizer-r {
  background-color: $medium-gray-color;

  height: 100%;
  width: 1px;
}

.resizer-b {
  background-color: $medium-gray-color;

  height: 1px;
  width: 100%;
}

.top-block {
  overflow-y: scroll;
  position: relative;
  min-height: 200px;
  max-height: 610px;
}
.top-block::-webkit-scrollbar {
  width: 0px;
}

.left-bar_separator {
  cursor: row-resize;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  width: 100%;
  border-top: 1px solid $medium-gray-color;
  // margin-top: 8px;
}
