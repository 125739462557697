@use "shared" as *;

.filters {
  display: flex;
  flex-direction: column;
  background-color: $white-color;
  z-index: 2;
  overflow-y: scroll;
  margin-bottom: 8px;
}
.filters_folder {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.filters::-webkit-scrollbar {
  width: 0px;
}

.filters::-webkit-scrollbar-track {
  background-color: transparent;
}

.filters::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.root_filter {
  cursor: pointer;
  padding: 3px 6px 2px 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  background-color: $light-gray-color;
}

.root_filter_right {
  display: flex;
  align-items: center;
  gap: 4px;
}
.root_filter_right > p {
  @include table-head;
}


.filter_arrow {
  width: 24px;
  height: 24px;
  background-image: url('../../../../public/icons/arrow_drop_down.svg');
  background-size: 24px 24px;
}

.filter_arrow.open {
  transform: rotate(180deg);
}

.root_filter-subfolders {
  padding: 0 0 26px 0;
}

.selected_filters-wrapper {
  margin: 13px 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: 19px;
  gap: 4px;
}

.selected_filter {
  display: flex;
  padding: 5px 8px 6px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background-color: $black-color;
  color: $white-color;
}

.selected_filter-remove_icon {
  cursor: pointer;
  width: 19px;
  height: 19px;
  background-image: url("../../../../public/icons/close.svg");
  background-size: 9.987px 9.987px;
  background-repeat: no-repeat;
  background-position: center;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(219deg)
  brightness(102%) contrast(104%);
}

.filter_name {
  @include table;
  margin: 16px 0 16px 16px;
  opacity: 0.5;
}

.filters_wrapper {
  cursor: pointer;
  padding-left: 19px;
  flex-direction: column;
  display: flex;
  //   gap: 13px;
}

.filter {
  display: flex;
  flex-direction: column;
  //   gap: 8px;
  //   align-items: center;
}

.filter__info {
  display: flex;
  flex-direction: row;
  // gap: 8px;
  align-items: center;
  padding: 6.5px 0;
}

.filter__info:hover{
  background-color: $milky-coconut;
}

.filter__title {
  @include filter;
  padding-left: 8px;
  width: 100%;
}
.subfilter__arrow {
  width: 24px;
  height: 20px;
  background-image: url('../../../../public/icons/arrow_drop_down.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  margin-right: 8px;
}
.subfilter__arrow.open{
  transform: rotate(180deg);
}
.filter_no_icon {
  width: 26px;
  height: 20px;
}

.filter__chekbox_icon {
  width: 16px;
  height: 16px;
  border: 1px solid $medium-gray-color;
}

.filter_checkbox {
  background-color: $white-color;
  border: 1px solid $medium-gray-color;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter_checkbox_active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $black-color;
}

.filter_checkbox-square {
  width: 16px;
  height: 16px;
  border: 1px solid $medium-gray-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter_checkbox-square-active {
  width: 11px;
  height: 8px;
  background-image: url("../../../../public/icons/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px 8px;
  
}
.filter__ul {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.filter__ul-title-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}


.filter__ul-title {
  margin-left: 6px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #646464;
}

.filter__li-container {
  margin-left: 12px;
  border-left: 1px solid #e1e1e1;
  flex-direction: column;
}

.filter__li {
  margin-left: 20px;
  margin-top: 6px;
  display: flex;
  align-items: center;
}

.filter__li:first-child {
  margin-top: 0;
}

.filter__li-checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter__li-checkbox-active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0463e1;
}

.filter__li-name {
  background-color: #1d1d1b;
  border-radius: 12px;
  height: 24px;
  margin-left: 8px;
  color: #ffffff;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}
