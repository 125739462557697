// @use 'shared' as *;
@use 'shared' as *;

body {
  margin: 0;
  @include body-m;
}

* {
  box-sizing: border-box;
  word-wrap: break-word;
  margin: 0;
}

::-webkit-scrollbar {
  width: 7px;
  margin-right: 10px;
  right: 10px;
}

::-webkit-scrollbar-track {
  background: $white-color;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background:rgba(194, 193, 193, 0.4);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background:rgba(194, 193, 193, 1);
}



input {
  all: unset;
}

:root {
  --Medium-gray: #C2C1C1;
  --white: #FFF
}


@font-face {
  font-family: 'Moderat';
  src: url('./fonts/Moderat-Trial-Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Simplon Mono';
  src: url('./fonts/SimplonMono-Regular-WebTrial.woff2') format('woff2');
  font-weight: 400;
}
