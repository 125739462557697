@use "shared" as *;

.file_checkbox {
    width: 16px;
    height: 16px;
    border: 1.5px solid $gray-color;
    margin: 0 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white-color;
  }
  
  .file_checkbox-square-active {
    width: 11px;
    height: 8px;
    background-image: url("../../../../public/icons/check.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 11px 8px;
  }