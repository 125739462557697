@use "shared" as *;

.browser {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.browser__title {
  background-color: #dcdbd9;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 32px;
}

.browser__element {
  cursor: pointer;
  width: 100%;
  background-color: $white-color;
  padding: 8px 10px 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
}

.browser__element-pad {
  padding-left: 29px;
}

.browser__element_separator {
  bottom: -11px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: $medium-gray-color;
  position: absolute;
}

.browser__element_arrow {
  width: 16px;
  height: 16px;
  background-image: url("../../../../public/icons/arrow_drop_down.svg");
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
}

.browser__element_plus {
  width: 20px;
  height: 20px;
  background-image: url("../../../../public/icons/plus.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.browser__element_active {
  background-color: $black-color;
  .browser__element_name {
    color: $white-color;
  }
  .browser__element_storage_icon {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(219deg)
      brightness(102%) contrast(104%);
  }
  .browser__element_library_icon {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(219deg)
      brightness(102%) contrast(104%);
  }
  .browser__element_arrow {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(286deg)
      brightness(103%) contrast(103%);
  }
  .browser__element_plus,
  .browser__element_fav_icon,
  .browser__element_trash_icon {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(286deg)
      brightness(103%) contrast(103%);
  }
}

.browser__element_left-parth {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  // position: relative
}

.browser__element_name {
  @include body-m;
  font-size: 15px;
  font-weight: 400;
  //   color: $white-color;
}

.browser__element_storage_icon {
  width: 16px;
  height: 16px;
  background-image: url("../../../../public/icons/browser_folder.svg");
  background-size: 18px 18px;
  background-position: center;
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(188deg)
    brightness(84%) contrast(114%);
}
.browser__element_library_icon {
  width: 20px;
  height: 20px;
  background-image: url("../../../../public/icons/library.svg");
  background-size: 18px 18px;
  background-position: center;
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(188deg)
    brightness(84%) contrast(114%);
}

.browser__element_fav_icon {
  width: 20px;
  height: 20px;
  background-image: url('../../../../public/icons/bookmark.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 11px;
}
.browser__element_trash_icon {
  width: 20px;
  height: 20px;
  background-image: url("./icons/trash.svg");
}
.browser__upload {
  background-color: #f1f1f1;
  color: #0463e1;
}

.folders_wrapper {
  cursor: pointer;
  display: none;
  padding-left: 13px;
  flex-direction: column;
}

.folders_wrapper:first-of-type {
  padding: 5px 0px 33px 8px;
  border-bottom: 1px solid $medium-gray-color;
  margin-bottom: 12px;
}

.folders_wrapper_open {
  transform: scaleY(100%);
  display: flex;
  opacity: 1;
}

.folder {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.folder_info_active {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  padding: 7px 0;
  @include filter;
  background-color: $black-color;
  color: $white-color !important;
  .folder_minus_icon,
  .folder_plus_icon,
  .folder_icon,
  .folder_open_icon,
  .browser__element_arrow {
    filter: invert(100%) sepia(50%) saturate(2%) hue-rotate(89deg)
      brightness(111%) contrast(101%);
  }
  .info {
    color: $white-color;
  }
}

.folder_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include filter;
  padding: 7px 0;
}

.info {
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  @include filter;
}

.files_count {
  position: absolute;
  top: -2px;
  right: -17px;
  display: flex;
  @include files_count;
  color: $white-color;
}

.folder_minus_icon {
  width: 16px;
  height: 16px;
  background-image: url("../../../../public/icons/minus.svg");
}

.folder_plus_icon {
  width: 16px;
  height: 16px;
  background-image: url("../../../../public/icons/add.svg");
}

.folder_icon {
  width: 16px;
  height: 16px;
  background-image: url("../../../../public/icons/browser_folder.svg");
  background-size: 16px;
  transition: 0.3s;
}

.folder_info:hover {
  background-color: $milky-coconut;
}

.folder_open_icon {
  width: 16px;
  height: 16px;
  background-image: url("../../../../public/icons/browser_folder_open.svg");
}
.icon {
  width: 24px;
  height: 16px;
}

.library_folders_wrapper {
  padding: 8px 0 8px 22px;
  width: 100%;
  display: flex;
  gap: 6px;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 1px solid $medium-gray-color;
}

.library_folder {
  @include body-m;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 10px 10px 8px 8px;
  display: flex;
}

.library_folder_active {
  background-color: $black-color;
  color: $white-color;
  .library_folder_icon {
    filter: invert(100%) sepia(50%) saturate(2%) hue-rotate(89deg)
      brightness(111%) contrast(101%);
  }
}

.library_folder_icon {
  width: 20px;
  height: 20px;
}

.storage_folders {
  margin-top: 8px;
}
