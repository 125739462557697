@use "shared" as *;

.tag-edit-menu {
  align-self: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: $black-color;
  overflow: hidden;
  //   height: 37px;
  //   width: 313px;
  position: absolute;
  left: 50%;
  bottom: calc(100% + 5px);
  transform: translate(-50%, 0%);
  z-index: 5;
}

.tag-edit-menu-item {
  position: relative;
  padding: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.tag-edit-menu-item-separator{
  content: '';
  right: 0;
  position: absolute;
  height: 21px;
  width: 1px;
  top: 11px;
  background-color: $hover-black-color;  
}
// .tag-edit-menu-item:nth-child(even)::before {
//   content: '';
//   right: 0;
//   position: absolute;
//   height: 21px;
//   width: 1px;
//   top: 11px;
//   background-color: $hover-black-color;  
// }

// .tag-edit-menu-item:nth-child(even)::after {
//   content: '';
//   left: 0;
//   position: absolute;
//   height: 21px;
//   width: 1px;
//   top: 11px;
//   background-color: $hover-black-color;  
// }


.tag-edit-menu-item:hover {
  background-color: $hover-black-color;
}

.tag-edit-menu-item > p {
  @include table;
  color: $white-color;
}

.tag-edit-menu-item-edit_icon {
  width: 13px;
  height: 13px;
  background-image: url("./icons/tag_edit.svg");
  background-size: 13px 13px;
  background-position: center;
  margin-bottom: 2px;
}

.tag-edit-menu-item-copy_icon {
  width: 13px;
  height: 13px;
  background-image: url("./icons/tag_copy.svg");
  background-size: 13px 13px;
  background-position: center;
  margin-bottom: 2px;
}

.tag-edit-menu-item-paste_icon {
  width: 13px;
  height: 13px;
  background-image: url("./icons/tag_paste.svg");
  background-size: 13px 13px;
  background-position: center;
  margin-bottom: 2px;
}

.tag-edit-menu-item-delete_icon {
  width: 13px;
  height: 13px;
  background-image: url("./icons/tag_delete.svg");
  background-size: 13px 13px;
  background-position: center;
  margin-bottom: 2px;
}

.tag-edit-menu-item-approve_icon {
  width: 13px;
  height: 13px;
  background-image: url("../../../../public/icons/approve.svg");
  background-size: 16px 16px;
  background-position: center;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(88deg) brightness(102%) contrast(103%);
}

.tag-edit-menu-item-retry_icon {
  width: 13px;
  height: 13px;
  background-image: url("./icons/tag_replay.svg");
  background-size: 13px 13px;
  background-position: center;
  margin-bottom: 2px;
}

.tag-edit-menu-item-remove_icon {
  width: 13px;
  height: 13px;
  background-image: url("./icons/tag_remove.svg");
  background-size: 11px 11px;
  background-repeat: no-repeat;
  background-position: center;
}
