@use 'shared' as *;

.input {
    @include filter;
    outline: none;
    border: 1px solid $medium-gray-color;
    padding: 0px 10px;
    min-width: 30px;
    height: 39px;
    
}

.input:focus {
    border: 1px solid $black-color;
}

.input::placeholder {
    @include filter;
    opacity: 0.2;;
}
