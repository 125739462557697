/*
@use 'shared' as *;
*/

$black-color: #000;
$hover-black-color: #3e3e3e;
$gray-color: #aaa8a5;
$active-blue-color: #0463e1;
$light-gray-color: #efefef;
$medium-gray-color: #c2c1c1;
$unit-gray-color: #00000073;
$tag-color: #97ffc6;
$light-tag-color: #ccffe3;
$white-color: #fff;
$active-toggle-color: #58ffa4;
$milky-coconut: #f9f9f9;

@mixin body-m {
  font-family: "Moderat";
  font-weight: 500;
  line-height: 116%;
  letter-spacing: -0.14px;
  color: $black-color;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

@mixin filter {
  font-family: "Moderat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  color: $black-color;
  margin: 0;
}

@mixin table {
  color: $black-color;
  font-family: "Simplon Mono";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.65px;
}

@mixin tab {
  font-family: "Moderat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.15px;
}

@mixin settings {
  color: $black-color;
  font-family: "Moderat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 106%;
  letter-spacing: -0.3px;
}
@mixin table-head {
  font-family: "Simplon Mono";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 106%;
}
@mixin file-name {
  color: $black-color;
  font-family: "Moderat";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 116%;
  letter-spacing: -0.36px;
}

@mixin select {
  font-family: "Simplon Mono";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.55px;
}

@mixin files_count {
  font-family: "Moderat";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.1px;
}

@mixin cursor-default {
  cursor: url("./cursors/default.svg") 9 9, auto;
}

@mixin cursor-pointer {
  cursor: url("./cursors/pointing.svg") 9 9, auto;
}

@mixin cursor-grab {
  cursor: url("./cursors/grab.svg") 9 9, auto;
}

@mixin cursor-resize-up_down {
  cursor: url("./cursors/upDown.svg") 9 9, auto;
}
