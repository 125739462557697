@use "shared" as *;

.tag_select {
  cursor: default;
  display: flex;
  padding: 5px 8px 6px 8px;
  align-items: center;
  // gap: 12px;
  border-radius: 4px;
  background-color: $light-gray-color;
  border: 1px solid $light-gray-color;
  // max-height: 28px;
  position: relative;
}

.tag_select-open {
  border: 1px solid $medium-gray-color;
}

.tag_select > p {
  @include filter;
}

.select_placeholder {
  opacity: 0.2;
}

.preview_select {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include filter;
  border: 1px solid $medium-gray-color;
  padding: 0px 10px;
  min-width: 30px;
  height: 39px;
}

.tag_select-arrow {
  cursor: pointer;
  width: 18px;
  height: 18px;
  background-image: url("../../../../public/icons/arrow_drop_down.svg");
  background-position: center;
}

.tag_select-arrow.open {
  transform: rotate(180deg);
}

.tag_select-menu {
  z-index: 4;
  left: 0;
  top: 110%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 202px;
  height: 440px;
  background-color: $white-color;
  padding: 16px 5px;
  gap: 22px;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.preview_select-menu {
  z-index: 4;
  left: 0;
  top: 110%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 440px;
  background-color: $white-color;
  padding: 16px 5px;
  gap: 22px;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
.select_menu-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.select_menu-group_title {
  @include select;
  opacity: 0.5;
  padding: 5px 0 5px 11px;
}

.select_menu-group_item {
  padding: 5px 5px 5px 11px;
}


.select_menu-group_item.active{
  background-color: $light-gray-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.select_menu-group_item-icon{
  cursor: pointer;
  height: 19px;
  width: 19px;
  background-image:  url("../../../../public/icons/approve.svg");
  background-size: 19px 19px;
}
.select_menu-group_item:hover {
  background-color: $light-gray-color;
}
.select_menu-group_item > p {
  @include table;
}
