@use "shared" as *;

.library_image-wrapper {
  margin: 0 8px;
  height: 100%;
  overflow: scroll;
}

.library_image-wrapper::-webkit-scrollbar {
  display: none;
}

.library_image {
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  gap: 44px;
  padding-bottom: 44px;
}

.show_all-wrapper {
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $milky-coconut;
}

.category_title {
  @include tab;
  text-transform: capitalize;
}

.navigate_by_type{
  text-transform: capitalize;
  
}
// .category_title:first-letter{
//   text-transform: uppercase;
// }

.navigate_by-item {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.navigate_by-item-info {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.navigate_by-item-info > p {
  @include file-name;
}

.navigate_items-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.navigate_item {
  display: flex;
  flex-direction: column;
  gap: 14px;

}

.navigate_preview{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 290px;
  height: 196px;
  background-color: $milky-coconut;
}
.navigate_preview_fav{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

}
.navigate_preview_fav_icon {
  width: 20px;
  height: 20px;
  background-image: url('../../../../public/icons/bookmark.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 11px;
}