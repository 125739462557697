@use "shared" as *;

.file_tile-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
  background-color: $white-color;
  // height: 283px;
  position: relative;
}

.file_tile {
  cursor: pointer;
  padding: 10px 10px 14px 10px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.file_tile_active {
  background-color: $milky-coconut;
  .file_tile-chexbox,
  .file_tile-settings-wrapper,
  .file_tile__fav {
    display: flex;
  }
}

.file_tile:hover {
  background-color: $milky-coconut;
  .file_tile-chexbox,
  .file_tile-settings-wrapper,
  .file_tile__fav {
    display: flex;
  }
}

.file_tile-image {
  position: relative;
  width: 183px;
  height: 183px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file_tile-image > img {
  width: 183px;
  height: 183px;
  object-fit: cover;
}

.file_tile-name {
  @include tab;
}
.file_tile-size {
  @include tab;
  font-size: 14px;
}

.file_tile-text {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.file_tile__fav {
  display: none;
  cursor: pointer;
  top: 0;
  right: -2px;
  position: absolute;
  width: 24px;
  height: 18px;
  background-image: url("../../../../public/icons/bookmark.svg");
  background-size: 24px 18px;
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(78%) sepia(10%) saturate(83%) hue-rotate(357deg)
    brightness(86%) contrast(86%);
  padding: 0 20px;
}

.file_tile__fav_active {
  display: flex;
  filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(9deg) brightness(97%)
    contrast(103%);
}

.file_tile-chexbox {
  top: 12px;
  left: 12px;
  z-index: 3px;
  position: absolute;
  display: none;
  transition: 0.3s;
}

.file_tile-settings-wrapper {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0px;
  right: 0px;
  display: none;
}

.file_tile-settings {
  padding: 19px 10px 17px 9px;
  background-image: url("../../../../public/icons/settings.svg");
  background-size: 9px 16px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.file_tile-settings_menu {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: $white-color;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  top: 38px;
  right: 10px;
  z-index: 2;
}

.file_tile-settings_menu_item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  background-color: $white-color;
  padding: 0 16px 0 10px;
  width: 100%;
}

.file_tile-settings_menu_item > p {
  @include table;
}

.file_tile__tag-cell {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-self: start;
  width: 183px;
}

.folder-tile-icon {
  width: 20px;
  height: 17px;
  background-image: url("../../../../public/icons/folder.svg");
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.folder-big-icon {
  width: 183px;
  height: 183px;
  background-image: url("../../../../public/icons/folder.svg");
  background-size: 183px;
  background-position: center;
  background-repeat: no-repeat;
}

.folder-name-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.folder-preview-images {
  height: 100%;
  width: 100%;
  // background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-image-0 {
  width: 141.568px;
  height: 141.568px;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
}

.preview-image-1 {
  width: 156.736px;
  height: 156.736px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
}
.preview-image-2 {
  object-fit: cover;
  position: absolute;
  width: 123.872px;
  height: 123.872px;
  top: 16.5px;
  right: 18px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
}
