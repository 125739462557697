@use "shared" as *;

.preview_unit {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    opacity: 0.45;
}

.preview_unit>p{
    @include table;
}

.preview_unit-arrow {
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-image: url("../../.././../public/icons/arrow_drop_down.svg");
  background-size: 24px 24px;

}

.preview_unit-arrow.open {
  transform: rotate(180deg);
}


.preview_unit-info{
    display: flex;
    flex-direction: column;
    gap: 10px;
}