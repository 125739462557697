@use "shared" as *;

.textarea {
  @include filter;
  outline: none;
  border: 1px solid $medium-gray-color;
  padding: 14px 12px;
  min-width: 30px;
  height: 72px;
  resize: none;
}

.textarea:focus {
  border: 1px solid $black-color;
}

.textarea::placeholder {
  @include filter;
  opacity: 0.2;
}

// .segment__desc>textarea {
//     overflow: auto;
//     resize: none;
//     max-height: 30px;
//     height: 27px;
// }

// .segment__desc>textarea:focus {
//     border: none;
// }

.textarea::-webkit-scrollbar {
    display: none;
}

// .textarea_wrapper {
//     border: none;
//     width: 100%;
//     padding: 0;
// }
