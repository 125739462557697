@use "shared" as *;

.files__table_head-checkbox {
    height: 16px;
    width: 16px;
    min-width: 16px;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .files__table_head-checkbox-icon {
    &_part-selected .gray-stroke {
      stroke: $black-color;
    }
  
    &_all-selected .gray-fill {
      fill: $black-color;
    }
  }