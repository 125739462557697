@use "shared" as *;

.app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.window {
  height: 100%;
  display: flex;
}

body::-webkit-scrollbar {
  width: 0;
}

.pupu{
  // margin: 8px 0 0 8px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // overflow-y: scroll;
  // overflow-x: hidden;
  flex: 2.8;
  // width: 340px;
  // min-width: 340px;
  border-left: 1px solid $medium-gray-color;
}

.pupu>p{
@include filter;
opacity: 0.2;
}