@use "shared" as *;

.upload {
  display: flex;
  flex-direction: row;
  flex: 10;
  overflow: hidden;
}

.upload__area {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e6e6e6;
  position: relative;
  user-select: none;
}

.upload-util-bar {
  height: 40px;
  background-color: #c7c6c4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.upload-util-bar__action-button {
  width: 101px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0463e1;
  color: #ffffff;
  position: relative;
  cursor: pointer;
}

// .upload-util-bar__action-button-icon {
//   width: 10px;
//   height: 10px;
//   margin-left: 6px;
//   background-image: url("../../../public/icons/triangle-white.svg");
//   background-size: 10px 10px;
//   fill: white;
// }

.upload-util-bar__action-drop-down {
  flex-direction: column;
  position: absolute;
  background-color: #ffffff;
  color: #000000;
  min-width: 193px;
  border: 1px solid #e1e1e1;
  top: 40px;
  right: 0;
  padding: 16px 0px;
  cursor: default;
}

.upload-util-bar__action-drop-down-element {
  height: 30px;
  padding: 0px 26px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
}
