@use "shared" as *;

.status__bar {
  padding: 8px 0;
  margin: 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  // border-bottom: 1px solid $medium-gray-color;
}

.status__bar_left_part {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.status__bar_arrows {
  display: flex;
  flex-direction: row;
  gap: 6px;
  @include cursor-pointer;
  margin-right: 16px;
  margin-left: 8px;
}

.status__bar_back_arrow {
  width: 20px;
  height: 18px;
  background-image: url("../icons/arrow_left_alt.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.status__bar_next_arrow {
  width: 20px;
  height: 20px;
  background-image: url("../icons/arrow_right_alt.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.status__bar_history {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
}

.status__bar_history-item {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
}

.status__bar_history > p {
  @include table;
}

.status__bar_history-item :last-child {
  opacity: 0.5;
}

.status__bar_history_chevron {
  width: 16px;
  height: 16px;
  background-image: url("../icons/chevron_right.svg");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.status__bar_history_copy_icon {
  @include cursor-pointer;
  width: 24px;
  height: 24px;
  background-image: url("../icons/copyLink.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 6px;
}

.status__bar_right_part {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.status__bar_tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.status__bar_tag {
  @include cursor-pointer;
  display: flex;
  padding: 5px 8px 6px 8px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background-color: $light-gray-color;
  @include filter;
}

.status__bar_right_part_icons {
  @include cursor-pointer;
}

.status__bar_search_wrapper {
  width: 320px;
  border-radius: 29px;
  border: 1px solid $black-color;
  padding: 7px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  display: none;

  // transform: scaleX(0);
  // opacity: 0;
  // transform-origin: right;
  // transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1),
  //   opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);
}

.status__bar_search_wrapper_open {
  display: flex
  // transform: scaleX(100%);
  // opacity: 1;
}

.status__bar_search_icon {
  @include cursor-pointer;
  width: 22px;
  height: 22px;
  background-image: url("../icons/search.svg");
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
}

.status__bar_search_input {
  height: 100%;
  width: 100%;
  @include filter;
}

.status__bar_search_close {
  @include cursor-pointer;
  width: 22px;
  height: 22px;
  background-image: url('../../../../public/icons/close.svg');
  background-size: 11px 11px;
  background-repeat: no-repeat;
  background-position: center;
}

