@use "shared" as *;

.settings__bar {
  padding: 8px;
  margin: 0px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $medium-gray-color;
}

.settings__bar_left_part {
  display: flex;
  flex-direction: row;
  align-items: center;
  // gap: 20px;
}
.settings__bttn {
  height: 40px;
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: row;
  padding: 12px 16px 12px 12px;
  align-items: center;
  gap: 6px;
  @include tab;
  min-width: 128px;
  background-color: $tag-color;

  .settings_star_icon {
    width: 21px;
    height: 15px;
    background-image: url("../icons/stars.svg");
    background-size: 21px 15px;
  }
}

// .settings__bttn_active {
//   background-color: $white-color;
//   color: $black-color;
//   flex-direction: row-reverse;
//   .settings__bttn_icon {
//     transform: rotate(180deg);
//   }
// }

.settings__tags_menu {
  display: flex;
  align-items: center;
  //   gap: 27px;
  transform: scaleX(0);
  opacity: 0;
  transform-origin: left;
  transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1),
    opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);
}

.settings__tags_menu_open {
  transform: scaleX(100%);
  opacity: 1;
}

.settings__tags_menu-switcher {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.settings__tags_ai-menu-bttn {
  border: 0;
  background-color: $tag-color;
  @include table;
  cursor: pointer;
  padding: 6px 10px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.settings__tags_ai-menu-retry {
  display: flex;
  align-items: center;
  gap: 4px;
  @include table;
  cursor: pointer;
}

.settings__tags_ai-menu-retry-icon {
  height: 24px;
  width: 24px;
  background-image: url("../../../../public/icons/replay.svg");
}

.settings_ai_tools {
  display: flex;
  background-color: $light-tag-color;
  height: 40px;
  padding: 12px 16px 12px 12px;
  align-items: center;
}
.settings_ai_tools-item {
  cursor: pointer;
  @include tab;
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.settings_ai_tools-item:first-child {
  padding-right: 16px;
  border-right: 1px solid rgba(117, 154, 134, 0.5);
}

.settings_ai_tools-item:last-child {
  padding-left: 16px;
}

.settings_ai_tools-retry_icon {
  width: 20px;
  height: 20px;
  background-image: url("../../../../public/icons/replay.svg");
  background-size: 20px 20px;
}

.settings_ai_tools-approve_icon {
  width: 20px;
  height: 20px;
  background-image: url("../../../../public/icons/approve.svg");
  background-size: 20px 20px;
}

.settings_group_dropdown {
  cursor: pointer;
  display: flex;
  // gap: 4px;
  align-items: center;

  position: relative;
}
.settings_group_dropdown > p {
  @include table;
  margin: 0 4px;
  text-transform: capitalize;
}

.settings_group_dropdown-icon {
  width: 24px;
  height: 24px;
  background-image: url("../icons/groupIcon.svg");
  background-size: 24px 24px;
}

.settings_group_dropdown-arrow {
  width: 24px;
  height: 24px;
  background-image: url("../../../../public/icons/arrow_drop_down.svg");
  background-size: 24px 24px;
}

.settings_group_dropdown-arrow.inactive {
  transform: rotate(180deg);
}

.settings_group_dropdown-menu {
  z-index: 3;
  position: absolute;
  width: 160px;
  top: 110%;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: $white-color;
}

.settings_group_dropdown-menu > li {
  text-transform: lowercase;
  background-color: $white-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  @include table;
  text-transform: capitalize;
}
.settings_group_dropdown-menu.inactive {
  opacity: 0;
  visibility: hidden;
}

.settings_group_dropdown-menu > li:hover {
  background-color: $light-gray-color;
}

.selected_arrow {
  width: 8px;
  height: 8px;
  background-image: url("../icons/selected.svg");
  background-size: 8px 8px;
}

.settings_group_dropdown-ungroup {
  cursor: pointer;
  @include table;
  margin: 16px;
}

.settings_group_dropdown-ungroup.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.settings_group_dropdown-ungroup.active {
  color: $active-blue-color;
}
