@use "shared" as *;

.ai_input{
  background-color: $light-tag-color;
  border: 1px solid $light-tag-color;
  padding: 10px 12px;
  min-width: 30px;
  height: 41px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ai_input > p {
  @include filter;
}

.ai_input-icons{
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.ai_input-approve-icon {
  cursor: pointer;
  height: 19px;
  width: 19px;
  background-image: url("../../../../public/icons/approve.svg");
  background-size: 19px 19px;
}
.ai_input-retry-icon {
  cursor: pointer;
  height: 19px;
  width: 19px;
  background-image: url("../../../../public/icons/replay.svg");
  background-size: 17px 17px;
  background-position: center;
}

.ai_input-remove-icon {
  cursor: pointer;
  width: 19px;
  height: 19px;
  background-image: url("../../../../public/icons/close.svg");
  background-size: 11px 11px;
  background-repeat: no-repeat;
  background-position: center;
}
