@use "shared" as *;

.file_row-tag {
  cursor: default;
  display: flex;
  padding: 5px 8px 6px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background-color: $light-gray-color;
  border: 1px solid $light-gray-color;
  position: relative;
}

.file_row-tag-selected {
  border: 1px solid $active-blue-color;
}

.file_row-tag-hovered {
  border: 1px solid $medium-gray-color;
  //   transition: 0.2s;
}

.file_row-tag-edited {
  border: 1px solid $medium-gray-color;
  background: $white-color;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
.file_roe-tag-empty {
  justify-content: flex-end;
  width: 100%;
}

.file_row-tag_remove-icon {
  cursor: pointer;
  width: 19px;
  height: 19px;
  background-image: url("../../../../public/icons/close.svg");
  background-size: 9.987px 9.987px;
  background-repeat: no-repeat;
  background-position: center;
}

.tag-input {
  width: 100%;
}

.file_row-tag > p {
  user-select: none;
}

