@use 'shared' as *;

.tabs {
    display: flex;
    flex-direction: row;

    align-items: center;
    // padding: 8px 0;
    margin: 0 8px;
    border-bottom: 1px solid $medium-gray-color;
    
}

.tabs__home-button {
    height: 100%;
    aspect-ratio: 1;
    margin-right: 2px;
    background-image: url("../../../../public/icons/home-alt.svg");
    background-size: 18px 19px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.tab {
    cursor: pointer;
    // height: 40px;
    padding:  10px;
    // aspect-ratio: 142/40;
    background-color: $white-color;
    display: flex;
    align-items: center;
    border-right: 1px solid $medium-gray-color;

}
// .tabs .tab:last-of-type{
//     background-color: red;
//     border-right: 1px solid $light-gray-color;
// }
.tab_active{
    padding: 17px 10px;
    background-color: $black-color;
    color: $white-color;
    border: none;
    .tab__icon, 
    .tab__close-button{ 
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(219deg) brightness(102%) contrast(104%);
    }
}

.tab__icon {
    width: 20px;
    height: 20px;
    background-image: url("../../../../public/icons/browser_folder.svg");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(188deg) brightness(84%) contrast(114%);
}

.tab__name {
    @include tab;
    margin: 0 12px 0 8px;
}


.tab__close-button {
    width: 16px;
    height: 16px;
    background-image: url("../../../../public/icons/cross.svg");
    background-size: 16px 16px;
}

.tabs__new-tab-button {
    cursor: pointer;
    height: 100%;
    // background-color: #F1F1F1;
    background-image: url("../../../../public/icons/cross1.svg");
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;
}