@use "shared" as *;

//TO DO
.files__table {
  // width: 1203px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
 
}


.files__table-splitmode {
  width: 1203px;
}

.files__table_head {
  height: 28px;
  min-height: 28px;
  width: 100%;
  display: grid;
  grid-template-columns: 0.2fr 0.36fr 4fr 2.32fr 2.32fr 2.32fr 0.28fr 0.28fr;
  // grid-template-columns: 20px 36px 400px 232px 232px 232px 28px 28px;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: $light-gray-color;
}

.files__table_head-tagsMode {
  height: 28px;
  min-height: 28px;
  width: 100%;
  display: grid;
  grid-template-columns: 0.2fr 0.36fr 2.3fr 1.63fr 1.65fr 2.03fr 1.53fr 2.02fr 0.28fr;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: $light-gray-color;
}

.files__table_head-cell {
  @include table-head;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // padding: 0px 10px;
}

.files__table_head-cell#checkbox {
  padding: 0 8px 0 8px;
}

.files__table_head-cell:last-child {
  background-color: $black-color;
  padding: 0px 10px;
}

.files__table-plus_icon {
  width: 10px;
  height: 11px;
  justify-content: center;
  background-image: url("../../../public/icons/plus.svg");
  background-size: 10px 11px;
  background-repeat: no-repeat;
  background-position: center;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(110deg) brightness(102%) contrast(101%);
}

.files__table_status-bar {
  height: 28px;
  min-height: 28px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 20px;
  position: sticky;
  bottom: 0px;
  z-index: 2;
  background-color: $light-gray-color;
}

.files__table_status-bar>p {
  @include table-head;
}

.files__table_status-bar_icons {
  display: flex;
  gap: 16px;
}

.list-icon {
  width: 28px;
  height: 28px;
  background-image: url("./icons/list.svg");
  opacity: 0.3;
  cursor: pointer;
}

.list-icon.active {
  opacity: 1;
}

.tiles-icon {
  cursor: pointer;
  width: 28px;
  height: 28px;
  background-image: url("./icons/tiles.svg");
  opacity: 0.3;
}

.tiles-icon.active {
  opacity: 1;
}

.files__tile {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 234px);
  padding: 0 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  justify-items: center;
  row-gap: 26px;
  padding-bottom: 27px;
}

.files__tile>* {
  position: relative;
  
}

.files__tile>*::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: $medium-gray-color;
}

.files__tile> :nth-child(n)::after {
  content: "";
  width: 1px;
  height: 90%;
  background-color: $medium-gray-color;
  position: absolute;
  top: 0;
  right: 0;
}

// .files__tile > *:nth-child(5n)::after {
//   width: 0 !important;
//   height: 0 !important;
// }

// .files__tile > *:last-child ::after {
//   width: 0 !important;
//   height: 0 !important;
// }

.files__tile::-webkit-scrollbar {
  display: none;
}

.table_wrapper {
  margin: 0 16px;
  height: 100%;
  overflow: scroll;
}

.table__upload {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  cursor: pointer;
}

.table_wrapper::-webkit-scrollbar {
  display: none;
}