@use "shared" as *;

.no_file {
  height: 100%;
  width: 324px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.no_file > p {
  @include filter;
  opacity: 0.2;
}

.preview__container {
  position: relative;
  width: 340px;
  min-width: 340px;
  max-width: 500px;
  margin-top: 8px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  // flex: 2.8;
  // border-left: 1px solid $medium-gray-color;
  gap: 16px;
}

.resizer_preview {
  position: absolute;
  height: 100%;
  left: -5px;
  top: 0;
  padding: 0 5px;
  cursor: col-resize;
}

.resizer_preview:hover{
  background: $milky-coconut;
}

.resizer-l {
  background-color: $medium-gray-color;
  height: 100%;
  width: 1px;
}

.preview__container::-webkit-scrollbar {
  width: 0;
}

.preview_header {
  width: 100%;
  padding: 8px 0;
  // margin: 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 21px;
  border-bottom: 1px solid $medium-gray-color;
}

.preview_header-profile_info {
  display: flex;
  justify-content: space-between;
}

.preview_header-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.preview_header-image > img {
  min-width: 324px;
  min-height: 324px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview_header-right_part {
  display: flex;
  align-items: center;
  gap: 8px;
}
.preview_text {
  @include settings;
}
.preview_header-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid $black-color;
  overflow: hidden;
}
.preview_header-avatar > img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.preview_header-left_part {
  display: flex;
  align-items: center;
  gap: 4px;
}

.preview_header-settings {
  cursor: pointer;
  padding: 10px 12px 9px 12px;
  background-image: url("../../../public/icons/settings.svg");
  background-size: 9px 16px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.preview_header-settings_menu {
  position: absolute;
  top: 120%;
  right: 11px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.preview_header-settings_menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  @include table;
  padding: 0 16px;
  cursor: pointer;
}

.settings-user_icon {
  width: 24px;
  height: 24px;
  background-image: url("./icons/user.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center;
}

.settings-logout_icon {
  width: 24px;
  height: 24px;
  background-image: url("./icons/logout.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center;
}

.preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 0 8px;
  padding-bottom: 20px;
  overflow-y: scroll;
  //   min-width: 340px;
}

.preview::-webkit-scrollbar {
  display: none;
}

.preview-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.preview-item-title {
  text-transform: capitalize;
  @include table;
}
.preview-item-value {
  @include filter;
}

.preview-file_name {
  @include file-name;
}

.preview-item-icons {
  display: flex;
  flex-direction: row;
}

.preview-item-fav_icon {
  cursor: pointer;
  padding: 10px 12px 9px 12px;
  background-image: url("../../../public/icons/bookmark.svg");
  background-size: 20px 16px;
  width: 24px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  filter: invert(78%) sepia(10%) saturate(83%) hue-rotate(357deg)
    brightness(86%) contrast(86%);
}
.preview-item-fav_icon_active {
  filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(9deg) brightness(97%)
    contrast(103%);
}

.preview-item-settings_icon {
  cursor: pointer;
  padding: 10px 12px 9px 12px;
  background-image: url("../../../public/icons/settings.svg");
  background-size: 9px 16px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.preview-item-access {
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.preview-item-access-image {
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 50%;
}

.preview-item-access-image > img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.dropdown-arrow {
  width: 24px;
  height: 24px;
  background-image: url("../../../public/icons/arrow_drop_down.svg");
  background-size: 24px 24px;
  position: relative;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.preview-parameters {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.preview-parameters > p {
  @include table;
  opacity: 0.5;
}

.preview-parameters-bttn {
  width: 169px;
  border: none;
  display: flex;
  height: 40px;
  padding: 10px 18px 12px 12px;
  align-items: center;
  gap: 8px;
  background-color: $black-color;
}

.preview-parameters-bttn > p {
  @include tab;
  color: $white-color;
}

.preview-parameters-bttn-icon {
  width: 20px;
  height: 20px;
  background-image: url("../../../public/icons/plus.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 13px 13px;
  margin-right: 8px;
  filter: invert(93%) sepia(94%) saturate(0%) hue-rotate(248deg)
    brightness(106%) contrast(106%);
}

// old

.preview__title {
  height: 40px;
  background-color: #dcdbd9;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.preview__image-container {
  height: 252px;
  padding: 20px 49px;
  display: flex;
}

.preview__image {
  width: 100%;
  object-fit: contain;
}

.preview__properties {
  height: 40px;
  background-color: #dcdbd9;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.preview__properties-container {
  margin: 30px 20px;
}

.preview__filename {
  margin-top: 30px;
  font-weight: 500;
  font-size: 18px;
}

.preview__details-title {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  margin-top: 8px;
}

.preview__br0 {
  margin-top: 30px;
  height: 1px;
  background-color: #dcdbd9;
}

.preview__details {
  display: flex;
  flex-direction: column;
}

.preview__details-line {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.preview__details-line > :first-child {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  text-transform: uppercase;
  color: #9d9d9d;
  font-size: 10px;
  font-weight: 500;
}

.preview__details-line:last-child {
  flex-grow: 1;
}

.preview__br1 {
  margin-top: 30px;
  height: 1px;
  background-color: #dcdbd9;
}

.preview__parametrs-title {
  font-size: 10px;
  margin-top: 8px;
  text-transform: uppercase;
}

.preview__tag-input {
  display: flex;
  height: 34px;
  align-items: center;
  padding: 0px 12px;
  border: 1px solid var(--GRAY, #e1e1e1);
  margin: 10px 0px;
  position: relative;
}

.preview__tag-input-name {
  font-size: 10px;
  font-weight: 500;
  color: #9d9d9d;
  width: 90px;
  text-transform: uppercase;
}

.preview__tag-input-input {
  flex-grow: 1;
  margin-right: 24px;
}

.preview__save-button {
  margin-top: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #646464;
  color: #ffffff;
  cursor: pointer;
}

.preview-ai-tags-controller {
  display: flex;
  height: 40px;
  padding: 12px 16px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background-color: $light-tag-color;
  width: 205px;
}

.ai-tags-controller-item {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  gap: 6px;
}
.ai-tags-controller-item > p {
  @include filter;
}

.ai-tags-controller-item-separator {
  background: rgba(117, 154, 134, 0.5);
  width: 1px;
  height: 22px;
}

.preview-ai-tags-retry-icon {
  height: 19px;
  width: 19px;
  background-image: url("../../../public/icons/replay.svg");
  background-size: 17px 17px;
  background-position: center;
}

.preview-ai-tags-approve-icon {
  height: 19px;
  width: 19px;
  background-image: url("../../../public/icons/approve.svg");
  background-size: 19px 19px;
}
