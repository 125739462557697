@use "shared" as *;

.ai-tag{
    cursor:default;
    position: relative;
    display: flex;
    padding: 5px 8px 6px 8px;
    align-items: center;
    border-radius: 4px;
    background-color: $light-tag-color;
    border: 1px solid $light-tag-color;
}
.ai-tag>p{
    padding-right: 6px;
}
.ai-tag-selected{
    border: 1px solid $active-blue-color;
}
.ai-tag-approve-icon{
    cursor: pointer;
    height: 19px;
    width: 19px;
    background-image:  url("../../../../public/icons/approve.svg");
    background-size: 19px 19px;
}