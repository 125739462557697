@use "shared" as *;



.tags_swicher_wrapper {
  cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 20px;
  }
  
  .switcher {
    overflow: hidden;
    width: 30px;
    height: 15px;
    border-radius: 8px;
    display: flex;
    padding: 0px;
    position: relative;
    align-items: center;
    transition-duration: 500ms;
    transition-property: padding;
    background-color: $light-gray-color;
  }
  
  .switcher_active {
    padding: 0px 0px 0px 15px;
    background-color: $black-color;
  }
  
  .switcher__stick {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $light-gray-color;
    border: 2px solid $white-color;
  }
  
  .switcher__stick_active {
    background-color: $black-color;
    transition-duration: 500ms;
  }