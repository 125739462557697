@use "shared" as *;

.file_row {
  width: 100%;
  display: grid;
  grid-template-columns: 0.2fr 0.36fr 4fr 2.32fr 2.32fr 2.32fr 0.28fr 0.28fr;
  // grid-template-columns: 20px 36px 400px 232px 232px 232px 28px 28px;
  // height: 68px;
  background-color: $white-color;
  cursor: pointer;
  border-bottom: 1px solid $medium-gray-color;
  align-items: center;
}

.file_row_is-being-dragged {
  border-bottom: 1px solid $white-color;
}

.file_row-tagsMode {
  background-color: $white-color;
  width: 100%;
  display: grid;
  grid-template-columns: 0.2fr 0.36fr 2.3fr 1.63fr 1.65fr 2.03fr 1.53fr 2.02fr 0.28fr 0.28fr;
  cursor: pointer;
  border-bottom: 1px solid $medium-gray-color;
  align-items: center;
}

.file_row:hover,
.file_row-tagsMode:hover {
  background-color: $milky-coconut;
}
.file_row__drag {
  margin-left: 4px;
  height: 100%;
  width: 100%;
  // width: 22px;
  // height: 22px;
  background-image: url("../icons/drag_handle.svg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 15px 0px 15px 6px;

}

.file_row__preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  min-width: 182px;
}

.file_row__preview-image {
  width: 67px;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.file_row__preview-image > img {
  width: 67px;
  height: 67px;
  object-fit: cover;
}

.file_row__preview > p {
  @include filter;
}

// .file_row__tag-cell {
//   @include filter;
//   height: 100%;
//   border-left: 1px solid $medium-gray-color;
//   padding-left: 15px;
//   display: flex;
//   flex-wrap: wrap;
//   gap: 4px;
//   align-self: start;
// }
.file_row_wrapper {
  display: flex;
  align-items: center;
  padding: 16px 0px 16px 0px;
  height: 100%;
}

.file_row__date {
  display: flex;
  align-items: center;
  height: 100%;
  border-left: 1px solid $medium-gray-color;
  @include filter;
  padding-left: 15px;
}

.file_row__tag-cell-wrapper {
  padding: 16px 0px 16px 0px;
  height: 100%;
}

.file_row__tag-wrapper {
  height: 100%;
  border-left: 1px solid $medium-gray-color;
  // min-width: 117px;
  // max-width: 150px;
}

.file_row__tag-cell {
  @include filter;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-self: start;
  padding: 0 15px;
  // display: flex;
  // flex-wrap: wrap;
  // gap: 4px;
  // align-self: start;
}

.file_row__size {
  display: flex;
  align-items: center;
  border-left: 1px solid $medium-gray-color;
  height: 100%;
  @include filter;
  padding-left: 15px;
  text-transform: uppercase;
}

.file_row__access {
  display: flex;
  height: 100%;
  padding-left: 15px;
  border-left: 1px solid $medium-gray-color;
  flex-direction: row;
  align-items: center;
}
.file_row__access-image_wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.file_row__access-image {
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid $white-color;
}

.file_row__access-image > img {
  width: 26px;
  height: 26px;
  object-fit: cover;
}

.file_row__fav {
  height: 52px;
  padding: 0px 7px 0px 11px;
  background-image: url("../../../../public/icons/bookmark.svg");
  background-size: 10px 13px;
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(78%) sepia(10%) saturate(83%) hue-rotate(357deg)
    brightness(86%) contrast(86%);
}

.file_row__fav_active {
  filter: invert(0%) sepia(97%) saturate(0%) hue-rotate(9deg) brightness(97%)
    contrast(103%);
}

.file_row__settings {
  width: 28px;
  height: 52px;
  padding: 19px 10px 17px 9px;
  background-image: url("../../../../public/icons/settings.svg");
  background-size: 9px 16px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.file_row__settings_menu {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: $white-color;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  top: 38px;
  right: 10px;
  z-index: 2;
}

.file_row__settings_menu-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  background-color: $white-color;
  padding: 0 16px 0 10px;
  width: 100%;
}

.file_row__settings_menu-item > p {
  @include table;
}

.file_row__add-multiple-tags-icon {
  width: 22px;
  height: 28px;
  padding: 5px 8px 6px 8px;
  background-image: url("../icons/addTag.svg");
  background-size: 13px 13px;
  background-position: center;
  background-repeat: no-repeat;
}

.folder-row-icon {
  width: 20px;
  height: 67px;
  background-image: url("../../../../public/icons/folder.svg");
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}
