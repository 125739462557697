@use "shared" as *;

.work__screen{
    // border-left: 1px solid $medium-gray-color;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    width: 50%;
    // overflow: scroll;
}

// .temp{
//     width: 1208px;
// }